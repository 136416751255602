import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import theme from 'site-react/theme';

import logoList from './logos';

const imageStyle = (width) => css`
  max-height: calc(var(--space-base) * 16); /* 64px */
  max-width: 100%;
  width: ${width}px;
`;

const CompanyLogos = ({ logos, isGrid, noOfColumns = 4 }) => {
  const logosStyle = css`
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: calc(var(--space-base) * 24); /* 96px */
    justify-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: ${theme.breakpoints.lg}px) {
      display: ${isGrid ? 'grid' : 'flex'};
      grid-template-columns: repeat(${noOfColumns}, 1fr);
      justify-content: space-around;
      min-height: calc(var(--space-base) * 26); /* 104px */
    }
  `;

  const itemStyle = css`
    text-align: center;

    @media (min-width: ${theme.breakpoints.lg}px) {
      align-self: ${isGrid ? 'center' : 'flex-end'};

      &:nth-child(odd) {
        align-self: ${isGrid ? 'center' : 'flex-start'};
      }
    }
  `;
  return (
    <ul className={logosStyle}>
      {logos.map((logo) => {
        const { alt, src, width } = logoList[logo];
        return (
          <li className={itemStyle} key={logo}>
            <img
              alt={alt}
              className={imageStyle(width)}
              loading="lazy"
              src={src}
            />
          </li>
        );
      })}
    </ul>
  );
};

CompanyLogos.propTypes = {
  /**
   * Should the logos always be displayed in uniform grid columms?
   */
  isGrid: PropTypes.bool,

  /**
   * Array of logo names to show. For best results, provide 4 logos.
   */
  logos: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(logoList))).isRequired,

  /**
   * If the logos are displayed in a grid (isGrid), how many columns should that grid have?
   */
  noOfColumns: PropTypes.number,
};

export default CompanyLogos;
